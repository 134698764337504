import React from 'react';
import styles from './homepage.module.css';
import Video from '../../../assets/videos/buildings.mp4';
import QuietTown from '../../../assets/quietTown.svg';
import { BsArrowRight } from 'react-icons/bs';
// import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';


const Homepage = () => {
    return (
        <div className={styles["container"]}>
 
            <div className={styles["home-container"]}>

            <video 
                src={Video} 
                autoPlay
                loop
                muted
                type='video/mp4'
                 > </video>
                <div className={styles['homepage-overlay']}></div>
                {/* <div className={styles.overlay}>
                    <img src={QuietTown} alt={QuietTown} />
                </div> */}
                <div className={styles["text"]} >
                  <h1>DESIGN</h1>
                  <h2>ARCHITECTURE</h2>
                  <h3>CONSTRUCTION</h3>
                  <br />
                  <Link to='/portfolio' className={styles['link']}>
                        <button 
                          
                        > <p>Explore our work</p> 
                         
                         <div className={styles.icon}> <BsArrowRight fontSize='1.2em' /> </div>
                          </button> 
                  </Link>
                </div>

                <div className={styles.quiet}>
                    <img src={QuietTown} alt={QuietTown} />
                </div>
                  
                
            </div>

            {/* <About /> */}

        
            </div>
    )
}

export default Homepage
