import React from 'react'
import styles from './footer.module.css'
import { RiBuilding2Line } from 'react-icons/ri';
import { FaTwitter, FaInstagram, FaFacebook  } from 'react-icons/fa';
import styled from 'styled-components';


const Twitter = styled(FaTwitter)`
    margin-right: 0.8em;
    font-size: 1.8em;
`;
const Instagram = styled(FaInstagram)`
    margin-right: 0.8em;
    font-size: 1.8em;
`;
const Facebook = styled(FaFacebook)`
    margin-right: 0.8em;
    font-size: 1.8em;
`;
// const Github = styled(FaGithub)`
//     margin-right: 0.8em;
//     font-size: 1.2em;
// `;

const Footer = () => {
    return (
        <div className={styles["footer"]}>

            <RiBuilding2Line fontSize="5em" />
            <div className={styles["footer-links"]}>

                <Facebook />
                <Instagram />
                <Twitter />
                {/* <div className={styles.type}>
                    <h3> WRITE OR CALL</h3>
                    <p> admin@pentfortarchitectureinc.com</p>
                    <p> 831-824-4517</p>
                </div>
                <div className={styles.type}>
                    <h3> VISIT</h3>
                    <p> 2175 N. Orange Avenue. Suite 202 Sarasota, Florida 34236</p>
                </div> */}
            </div>
            <div className={styles.rights}><p> PENTFORT ARCHITECTURE INC. ALL RIGHTS RESERVED 2021 </p></div>
        </div>
    )
}

export default Footer
